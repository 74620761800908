import React, { Suspense, lazy } from 'react';
import 'App.scss';
import { ThemeProvider } from '@material-ui/core/styles';
import theme, { typography } from 'styles/theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { RecoilRoot } from 'recoil';
import { CircularProgress } from '@material-ui/core';
import { BnProvider } from 'bondsports-utils';
import { useColors } from './hooks/useColors';

const Main = lazy(() => import('pages/Main'));
const Login = lazy(() => import('pages/Login'));

const AllWrappers: React.FC<any> = ({ children }) => {
  const { colors } = useColors();

  return (
    <BnProvider colors={colors} typography={typography}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
        <ToastContainer
          transition={Zoom}
          position="top-center"
          hideProgressBar={true}
          limit={1}
        />
      </ThemeProvider>
    </BnProvider>
  );
};

function App() {
  const { pathname } = useLocation();

  return (
    <div className={'app-wrapper ' + pathname}>
      <RecoilRoot>
        <AllWrappers>
          <Suspense fallback={<Fallback />}>
            <Switch>
              <Route path="/app" component={Main}></Route>
              <Route path="/login" component={Login}></Route>
              <Route path="/">
                <Redirect to="/login"></Redirect>
              </Route>
            </Switch>
          </Suspense>
        </AllWrappers>
      </RecoilRoot>
    </div>
  );
}

export function Fallback() {
  return (
    <div className="App-loading">
      <CircularProgress size={60} />
    </div>
  );
}

export default App;
