import { localStorage } from 'lib/storage';

export const TOKEN_NAME = 'satellizer_token';
export const USER_ID = 'userId';
/**
 * User Token Settings
 * @param token
 */

function setToken(token: string) {
  localStorage.setItem(TOKEN_NAME, token);
}

function getToken() {
  const authCheck = localStorage.getItem(TOKEN_NAME);

  return authCheck || false;
}

function removeToken() {
  // Clear user token data from localStorage
  localStorage.removeItem(TOKEN_NAME);
}

function getAuthHeaders() {
  const userToken = getToken();
  const options = {
    headers: {
      accept: "application/json, text/plain, */*",
      Authorization: userToken ? `Bearer ${userToken}` : null,
      "Content-Type": "application/json",
    },
  };

  return options;
}

/**
 * User Id settings
 * @param userId
 */
 function setUserId(userId: number) {
  localStorage.setItem(USER_ID, userId);
}

function getUserId() {
  const userIdCheck = localStorage.getItem(USER_ID);

  return userIdCheck || false;
}

function removeUserId() {
  // Clear user token data from localStorage
  localStorage.removeItem(USER_ID);
}

export const auth = {
  setToken,
  getToken,
  removeToken,
  setUserId,
  getUserId,
  removeUserId,
  getAuthHeaders,
};
